import * as React from "react"
import Layout from "../components/layout"
import Commission from "../components/Commission"

import { Link, graphql } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"


const PortfolioPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout>
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <h1 className={'text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold my-24 max-w-screen-lg'}>Portfolio.</h1>
        <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'}>
        {posts.map(post => {
                  const title = post.frontmatter.title || post.fields.slug
                  return (
                    <div key={post.fields.slug}>
                      <Link className={'block relative group'}  to={`/portfolio${post.fields.slug}`}>
                        <div>
                          <GatsbyImage image={post.frontmatter.coverImage.childImageSharp.gatsbyImageData}
                            alt={title}
                          />
                        </div>
                        <div className={'p-4 absolute inset-0 flex items-center justify-center bg-black/50 text-2xl font-bold text-white opacity-0 transition group-hover:opacity-100'}>{title}</div>
                      </Link>
                    </div>
                  )
              })}
        </div>
      </main>
      <Commission />
    </Layout>
  )
}

export default PortfolioPage

export const Head = () => (
    <>
    <title>Portfolio - Gill Morrow Art</title>
    <meta name="description" content="" />
    </>
)

export const pageQuery = graphql`
  query PortfolioLinks{
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {fileAbsolutePath: {regex: "\/portfolio/"}}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 500,
                height: 600,
                formats:[AUTO, WEBP, AVIF],
                placeholder: DOMINANT_COLOR,
                quality: 75,
                breakpoints: [360, 600],
                transformOptions:{
                  cropFocus: ATTENTION,
                  fit: COVER
                }
              )
            }
          }
        }
      }
    }
  }
`
